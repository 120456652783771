export const enum Event {
    Show,
}

type OnShowCallback = (content: any, options: any) => void;
export type OnChangeCallback = (toast: number, containerId?: number | string) => void;
type Callback = OnShowCallback;
type TimeoutId = ReturnType<typeof setTimeout>;

export interface EventManager {
    list: Map<Event, Callback[]>;
    emitQueue: Map<Event, TimeoutId[]>;
    on(event: string, callback: OnShowCallback): EventManager;
    off(event: string, callback?: Callback): EventManager;
    cancelEmit(event: Event): EventManager;
    emit(event: string, options: any): void;
}

export const eventManager: EventManager = {
    list: new Map(),
    emitQueue: new Map(),

    on(event: string, callback: Callback) {
        this.list.has(event) || this.list.set(event, []);
        this.list.get(event)!.push(callback);
        return this;
    },

    off(event, callback) {
        if (callback) {
            const cb = this.list.get(event)!.filter((i) => i !== callback);
            this.list.set(event, cb);
            return this;
        }
        this.list.delete(event);
        return this;
    },

    cancelEmit(event) {
        const timers = this.emitQueue.get(event);
        if (timers) {
            timers.forEach(clearTimeout);
            this.emitQueue.delete(event);
        }

        return this;
    },

    emit(event: string, ...args: any[]) {
        this.list.has(event) &&
            this.list.get(event)!.forEach((callback: Callback) => {
                const timer: TimeoutId = setTimeout(() => {
                    // @ts-ignore
                    callback(...args);
                }, 0);

                this.emitQueue.has(event) || this.emitQueue.set(event, []);
                this.emitQueue.get(event)!.push(timer);
            });
    },
};
