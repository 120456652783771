import React from 'react';
import './common.scss';
import './list.scss';
import SearchBox from './searchBox';

const FakePriceIndicator = () => {
	return (
		<div className="fake-flight-price">
			<div className="r0c0"></div>
			<div className="r0c1"></div>
			<div className="r0c2"></div>
		</div>
	);
};

const FakeFlightSummaryIndicator = () => {
	return (
		<div className="fake-flight-summary">
			<div className="r0c0"></div>
			<div className="r0c1"></div>
			<div className="r1c1"></div>
			<div className="r0c2"></div>
			<div className="r0c3"></div>
			<div className="r0c4"></div>
			<div className="r0c5"></div>
		</div>
	);
};
const FakeFlightIndicator = () => {
	return (
		<React.Fragment>
			<FakeFlightSummaryIndicator />
			<div>
				<FakePriceIndicator />
				<FakePriceIndicator />
			</div>
		</React.Fragment>
	);
};
class list extends React.Component {
	render() {
		return (
			<div className="loading-container">
				<section className="top">
					<section className="searchBox-container">
						<SearchBox />
					</section>
				</section>
				<div className="loading-container-content">
					<div className="calender"></div>
					<div className="flight-text">
						<div className="left"></div>
						<div className="right"></div>
					</div>
					<div className="search-result">
						<div className="filter">
							<ul className="left">
								<li></li>
								<li></li>
								<li></li>
								<li></li>
								<li></li>
								<li></li>
							</ul>
							<ul className="right">
								<li></li>
								<li></li>
								<li></li>
								<li></li>
								<li></li>
							</ul>
						</div>
						<div className="result">
							<FakeFlightIndicator />
							<FakeFlightIndicator />
							<FakeFlightIndicator />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default list;
