import React from 'react';
import { sendPV } from '@/src/sources/common/lightUbt';
import { eventManager } from '@/src/components/Modal/core/eventManager';

type PageP = Partial<{
	pageId: string;
}>;

const BasePage = ({ pageId }: PageP = {}) => {
	return function (ConnectComponent: any) {
		return class BaseComponent extends React.Component<any, { currentPageId?: string }> {
			constructor(props) {
				super(props);
				this.state = {
					currentPageId: pageId,
				};
			}

			componentDidMount() {
				document.addEventListener('visibilitychange', this.handleVisibilityChange);
				window.addEventListener('beforeunload', this.handleBeforeUnload);
				window.addEventListener('popstate', this.handlePopState);
			}

			componentWillUnmount() {
				document.removeEventListener('visibilitychange', this.handleVisibilityChange);
				window.removeEventListener('beforeunload', this.handleBeforeUnload);
				window.removeEventListener('popstate', this.handlePopState);
			}

			handleVisibilityChange = () => {
				if (document.visibilityState !== 'visible') {
					eventManager.emit('Page_Leave', { breakType: 'visibilitychange' });
				}
			};
			handleBeforeUnload = () => {
				// 关闭页签
				eventManager.emit('Page_Leave', { breakType: 'beforeunload' });
			};
			handlePopState = () => {
				// 浏览器前进后退
				eventManager.emit('Page_Leave', { breakType: 'popstate' });
			};

			/**
			 * 更新pageId,解决那些需要等接口返回才能确定pageId的情况
			 * @param currentPageId
			 */
			setCurrentPageId = async (currentPageId) => {
				this.setState({
					...this.state,
					currentPageId,
				});
				if (currentPageId) {
					sendPV(
						{
							pageId: currentPageId,
						}
					);
				}
			};

			render() {
				return (
					<ConnectComponent
						{...{
							...this.props,
							setCurrentPageId: this.setCurrentPageId,
						}}
					/>
				);
			}
		};
	};
};

export default BasePage;
