import React from 'react'
// import './searchBox.scss'

class SearchBox extends React.Component {
    render() {
        return (
            <section className='search-form'>

                <section className="top">
                    <div className="left"></div>
                    <div className="right"></div>
                </section>
                <section className="content">
                    <div className="left"></div>
                    <div className="center"></div>
                    <div className="right"></div>
                </section>
            </section>
        )
    }
}

export default SearchBox
